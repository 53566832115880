import { Entry } from 'contentful';
import React from 'react';
import { CardType } from 'repos/card';

import InfoCard from 'components/InfoCard';

type Props = {
  industries: Entry<CardType>[];
  industriesTitle: string;
  subtitle: string;
  title: string;
  useCases: Entry<CardType>[];
  useCasesTitle: string;
};

export default function SolutionsBlock(props: Props) {
  const {
    industries,
    industriesTitle,
    subtitle,
    title,
    useCases,
    useCasesTitle,
  } = props;

  return (
    <section className="py-24">
      <div className="mx-auto mb-16 max-w-7xl px-6">
        <h2 className="text-r-3xl text-center font-bold">{title}</h2>
        <p className="text-r-lg m-auto mt-8  text-center">{subtitle}</p>
      </div>
      <div className="mx-auto flex max-w-7xl flex-col gap-8 px-6 md:flex-row">
        <div className="flex-1">
          <p className="text-r-2xl font-bold underline">{useCasesTitle}</p>
          <div className="flex flex-col gap-10 pt-10">
            {useCases.map((useCase) => (
              <InfoCard key={useCase.sys.id} card={useCase} />
            ))}
          </div>
        </div>
        <div className="flex-1">
          <p className="text-r-2xl font-bold underline">{industriesTitle}</p>
          <div className="flex flex-col gap-10 pt-10">
            {industries.map((industry) => (
              <InfoCard key={industry.sys.id} card={industry} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
