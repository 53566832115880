import { Entry } from 'contentful';
import Image from 'next/image';
import { CardType } from 'repos/card';

type InfoCardProps = {
  card: Entry<CardType>;
};

export default function InfoCard(props: InfoCardProps) {
  const { card } = props;

  return (
    <div className="flex items-start gap-4">
      <Image
        src={`https:${card.fields.image.fields.file.url}`}
        alt={card.fields.title}
        width={50}
        height={50}
      />
      <div className="flex-1">
        <p className="text-r-xl mb-2 font-bold">{card.fields.title}</p>
        <p className="text-r-lg">{card.fields.description}</p>
      </div>
    </div>
  );
}
