import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import runnerImage from '../public/runner.svg';

type Props = {
  title: string;
  subtitle: string;
  buttonText: string;
};

export default function HeroBlock({ title, subtitle, buttonText }: Props) {
  const { t } = useTranslation();

  return (
    <section className="bg-[#384250] text-white">
      <div className="mx-auto flex max-w-7xl px-6">
        <div className="z-10 w-[520px] self-center">
          <h1 className="text-r-5xl my-8 font-bold">{title}</h1>
          <p className="text-r-lg mb-8 max-w-lg text-white">{subtitle}</p>
          <a
            href="https://meetings.hubspot.com/info3139"
            className="btn-primary"
            target="_blank"
            rel="noreferrer noopener"
          >
            {buttonText}
          </a>
        </div>
        <div className="pointer-events-none relative min-h-[695px] flex-1 select-none">
          <div className="absolute -left-[150px] scale-75 -scale-x-75 md:-left-0 md:scale-100">
            <Image
              src={runnerImage}
              layout="fixed"
              width="935"
              height="695"
              alt={t('runner_exercising')}
              priority
            />
          </div>
        </div>
      </div>
    </section>
  );
}
